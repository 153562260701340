
import {Component, Vue} from "vue-property-decorator";
import {TaskDurum} from "@/plugins/uyap-plugin/enum/TaskDurum";
import {ITask, ITasks, PlayerDurum} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {Socket} from "vue-socket.io-extended";

@Component({
  components: {}
})
export default class TopluTebligatGonderIslemDetayView extends Vue {
  @Socket('updateTaskDetails')
  onUpdateTask() {
    this.load()
  }

  onFly = false;
  downloading = false;
  headers = [
    {text: 'Esas No', value: 'esasNo'},
    {text: 'Birim Adı', value: 'birimAdi'},
    {text: 'Durum', value: 'status'},
  ];
  seciliHataliGorevler = [];
  items = [];
  loading = false;
  hataliHeaders = [
    {text: 'Esas No', value: 'esasNo'},
    {text: 'Birim Adı', value: 'birimAdi'},
    {text: 'Açıklama', value: 'description'},
    {text: 'Durum', value: 'status'},
  ];
  taskDurum = TaskDurum;
  playerDurum = PlayerDurum;

  get isFirstTask(): boolean {
    return this.$store.getters.getSiradakiGorevler[0]?.id == this.$route.params.id;
  }

  get paternTask(): ITask {
    let tasks: ITasks = this.$store.getters.getGorevler;
    let allTaskt: Array<ITask> = [...tasks.incomplete_tasks, ...tasks.completed_tasks];
    return allTaskt.find((task: ITask) => task.id == Number(this.$route.params.id)) as ITask;
  }

  async mounted(){
    await this.load()
  }

  async load() {
    try {
      this.items = (await this.$http.get('/api/v1/task/' + this.$route.params.id + '/child-tasks')).data;
    } catch (e) {
      this.items = [];
    }
  }

  downloadExcel() {
    this.downloading = true;
    this.$helper.download('Excel Sonuçlar.xlsx', '/api/v1/export-to-excel/' + this.$route.params.id).finally(() => {
      this.downloading = false;
    });
  }

  async durdurDevamEt() {
    if (this.$store.getters.getPlayerDurum == this.playerDurum.oto) {
      this.$socket.client.emit("player", this.playerDurum.durduruluyor);
    } else {
      this.$socket.client.emit("player", this.playerDurum.oto);
    }
  }

  selectAllToggle() {
    let hatalilar = this.items.filter((item: ITask) => (item.status == this.taskDurum.BITTI_HATALI || item.status == this.taskDurum.BITTI_EKSIK));
    if (this.seciliHataliGorevler.length == hatalilar.length) {
      this.seciliHataliGorevler = [];
    } else {
      this.seciliHataliGorevler = hatalilar;
    }
  }

  async tryAgainCreateTask() {
    if (this.seciliHataliGorevler.length > 0) {
      this.onFly = true;
      let tasks: Array<ITask> = [];
      this.seciliHataliGorevler.forEach((item: any) => {
        let task: ITask = {...item};
        task.id = null;
        task.status = TaskDurum.SIRADA;
        task.response = null;
        task.parent_id = null;
        tasks.push(task);
      });
      tasks[0].aciklama = "Gönderilemeyen tebligat taleplerinin gönderilmesi."
      try {
        await this.$store.dispatch('postTasks', tasks);
        let req = { ids: this.seciliHataliGorevler.map((gorev: ITask) => gorev.id), status: TaskDurum.YENIDEN_BASLATILDI};
        await this.$http.post('/api/v1/task/update-status', req);
        await this.$store.dispatch('getTasksFromServer');
        await this.load();
        await this.$router.push('/islemler');
      } catch (e) {
        console.error(e)
      } finally {
        this.seciliHataliGorevler = [];
        this.onFly = false;
      }
    } else {
      this.$toast.info('Yeniden başalatılacak görevleri seçiniz.')
    }
  }
}
