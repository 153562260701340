import { render, staticRenderFns } from "./TopluTebligatGonderIslemDetayView.vue?vue&type=template&id=06e940ab&scoped=true"
import script from "./TopluTebligatGonderIslemDetayView.vue?vue&type=script&lang=ts"
export * from "./TopluTebligatGonderIslemDetayView.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06e940ab",
  null
  
)

export default component.exports